import Vue from "vue/dist/vue.esm";
import modal from "modal";
import Api from "api";

document.addEventListener("turbolinks:load", () => {
  const pdf_form = new Vue({
    el: "#pdf_form",
    components: {
      modal,
    },
    data: {
      showModal: false,
      messages: [],
      state: "idle", // idle, saving, success, error
      pdfContact: {
        promotion_type: "free_planting_guide",
        email: "",
      },
    },
    computed: {},
    methods: {
      savePdfContact() {
        this.state = "saving";
        Api()
          .post(`/admin/promotion_signups`, {
            promotion_signup: this.pdfContact,
          })
          .then((response) => {
            this.state = "success";
            this.messages.push(response.data.message);
          })
          .catch((error) => {
            this.state = "error";
            console.log(error);
          });
      },
    },
    mounted() {
      if (window.location.pathname === "/testimonials") {
        this.showModal = true;
      }
    },
  });
  // add click event listener for button with class name 'pdf_form_init'
  const pdf_form_button = document.getElementsByClassName("pdf_form_init");

  // Loop through the collection of elements
  for (var i = 0; i < pdf_form_button.length; i++) {
    pdf_form_button[i].addEventListener("click", function(e) {
      e.preventDefault();
      var pf = pdf_form;
      pf.showModal = true;
    });
  }
});
