import Vue from 'vue/dist/vue.esm';
import modal from "modal";

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: '#new_contact',
    components: {
      modal
    },
    data: {
      showModal: false,
      emergencyInfo: '',
      service: ''
    },
    computed: {
      isEmergency() {
        return this.service == 'TreeEmergency'
      }
    },
    methods: {
      onChangeService(e) {
        let val = e.target.value
        if(val == "TreeEmergency") {
          this.showModal = true
        }
      }
    }
  })
})
