import axios from 'axios'
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')

export default() => {
  return axios.create({
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    }
  })
}
